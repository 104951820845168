import React, { useState } from "react";
import Modal from "../Body/Modal.js";
export default ({ modal, setModal }) => {
    const [color2, setColor2] = useState(false);
    const [color1, setColor1] = useState(false);
    const [color3, setColor3] = useState(false);

    return (
        <>
            <div
                className="selection-container w-full flex text-[14px]">
                {console.log(color1, "color1----------")}
                <form method="post" id="calculator-form" className="car-shipping-form flex flex-col w-[100%] gap-10" phx-submit="submit">
                    <div className="flex flex-col gap-[10px]">
                        <input name="_csrf_token" type="hidden" value="WT9fWxUGcSUTCiUeIi12PEJ6BEMSMwIokgf" />
                        <div className="directions-container flex flex-col gap-10 md:flex-row">
                            <div className="zip-input w-[100%]">
                                <div className="sds-input-container">
                                    <input aria-required="true" className="sds-text-field pickup-zip" id="pickupZip"
                                        inputmode="numeric" label="Pickup ZIP" maxlength="5"
                                        name="quote_request[pickup_zip]" placeholder="  " type="text" value=""
                                        onChange="close_errors();" />
                                    <label className="sds-label" for="pickupZip">Pickup ZIP</label>
                                </div>
                            </div>
                            <div className="zip-input w-[100%]">
                                <div className="sds-input-container">
                                    <input aria-required="true" className="sds-text-field dropoff-zip" id="dropoffZip"
                                        inputmode="numeric" label="Delivery ZIP" maxlength="5"
                                        name="quote_request[dropoff_zip]" placeholder="  " type="text"
                                        value="" onChange="close_errors();" />
                                    <label className="sds-label" for="dropoffZip">Delivery ZIP</label>
                                </div>
                            </div>
                        </div>
                        <div className="vehicle-container pt-[1em] js-mmy-select" id="mmy-select">
                            <div className="sds-input-container sds-input-container--dropdown">
                                <select aria-required="true" className="sds-text-field js-mmy-search-model-select"
                                    id="body_select" name="body_select" onChange="close_errors();">
                                    <option href='./body' value="">Choose a Body</option>
                                    <option value="1">Sedan</option>
                                    <option value="2">Convertible</option>
                                    <option value="3">Coupe</option>
                                    <option value="4">Wagon</option>
                                    <option value="5">Minivan</option>
                                    <option value="6">Crossover</option>
                                    <option value="7">SUV</option>
                                    <option value="8">Pickup Truck</option>
                                </select>
                                <label className="sds-label" for="body_select">Vehicle Body</label>
                                <svg className="sds-text-field__icon" height="16" width="16">
                                    <use ></use>
                                </svg>
                            </div>
                            <div className="sds-input-container">
                                <input aria-required="true" className="sds-text-field" id="pounds" inputmode="numeric"
                                    label="Weight in pounds" maxlength="5" name="pounds" placeholder="" type="text"
                                    value="" onChange="close_errors();" />
                                <label className="sds-label" for="pounds">Vehicle Weight ( in pounds )</label>
                            </div>
                        </div>
                        <div>
                            Estimate weight for your car, Small autos usually way around 2,500
                            pounds and large automobiles about 4,200 pounds. SUVs or trucks can range from
                            3,500 pounds to over 6,000 pounds
                        </div>
                        <div className="">
                            <fieldset aria-required="true " className="flex flex-col  gap-[1em]">
                                <legend className="radio-label sds-field-legend">Choose a transport option</legend>
                                <div className="radio-selection-wrapper md:flex-row flex flex-col gap-[1em]">
                                    <div onClick={() => setColor1()}
                                        className={`sds-radio bg-[#555] p-[15px] px-[50px] w-[100%] rounded-[5px] flex  items-center text-center border-[2px] border-[#555] active:bg-black active:border-[#00bfde]  text-white text-[16px] ${color1 === true ? "bg-black border-[2px] border-[#00bfde]" : "bg-[#555] border-[2px] border-[#555]"}`}
                                    >open</div>

                                    <div onClick={() => setColor2()}
                                        className={` p-[15px] px-[50px] w-[100%] text-center  text-[16px] rounded-[5px] active:bg-black active:text-white border-[2px] active:border-[#00bfde] ${color2 === true ? "bg-black text-white border-[2px] border-[#00bfde] " : "bg-white text-black border-[2px] border-white"}`}>Enclosed</div>


                                </div>
                                <center>
                                    <a className="modal-button"
                                        onClick={() => setModal(true)}>Open vs.
                                        enclosed transport</a>
                                </center>
                                <div className="submit-container">
                                    <div onClick={() => setColor3()}
                                        className={` text-white flex items-center justify-center px-[32px] border-[2px] border-[#250858] bg-[#250858] h-[56px] active:bg-[#250858] active:border-[#00bfde]  text-[16px] w-[100%] rounded-[5px] ${color3 === true ? "bg-[#250858] border-[#00bfde]" : "bg-[#532380] border-[#532380]"}`}
                                    >Calculate shipping cost</div>
                                </div>

                                <div className="text-[12px] w-full">

                                    By clicking "Place shipping order on USS AUTOMOTIVE LLC", I understand
                                    that I will be sent to USS AUTOMOTIVE LLC to finalize the shipping order.
                                    Cars.com is not responsible for, has no control over, and expressly
                                    disclaims all liability for, damages of any kind arising out of the use,
                                    reference to, or reliance on any information obtained or contained
                                    within the USS AUTOMOTIVE LLC site. I proceed at my own risk.

                                </div>

                            </fieldset>


                            <div id="loading" style={{ display: "none" }}>
                                <div className="loading-cover"></div>
                                <div className="loading-wrapper">
                                    <div className="loading-square">
                                        <div className="loading-center">
                                            <div className="loader"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </>
    )
}