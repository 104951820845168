import React from "react";
export default () => {
    return (
        <div className=" text-[16px] w-full">

            <div className=" bg-white h-[511px] rounded-[20px] p-[40px] w-[100%]" >
                <div className=" text-[24px] truncate">Your <span style={{ color: "#00bfde", fontweight: "700" }}>shipping quote</span></div>
                {/* <spark-disclaimer id="shipping-cost-disclaimer"> */}
                <a label="Powered by USS AUTOMOTIVE LLC" className="truncate sds-link--ext text-[12px] w-[90%]"
                    href="https://ussautobrokers.com/?id=quote">Powered by USS AUTOMOTIVE LLC</a>
                {/* </spark-disclaimer> */}

                <div className="shipping-info">
                    <div id="shipping-cost" className="text-[36px] text-[#212121] font-bold ">$0</div>
                    <dl className="fancy-description-list flex flex-col gap-[12px]">
                        <div className="flex flex-row gap-12px">
                            <div className="w-[50%]">Vehicle Body</div>
                            <ul className="vehicle-features-list">
                                <li id="results_body"> — </li>
                            </ul>
                        </div>
                        <hr />
                        <div className="flex flex-row gap-12px">
                            <div className="w-[50%]">Ship from</div>
                            <ul className="vehicle-features-list">
                                <li id="results_body"> — </li>
                            </ul>
                        </div>
                        <hr />
                        <div className="flex flex-row gap-12px">
                            <div className="w-[50%]">Ship to</div>
                            <ul className="vehicle-features-list">
                                <li id="results_body"> — </li>
                            </ul>
                        </div>
                        <hr />
                        <div className="flex flex-row gap-12px">
                            <div className="w-[50%]">transport option</div>
                            <ul className="vehicle-features-list">
                                <li id="results_body"> — </li>
                            </ul>
                        </div>
                        <hr />
                        <div className="flex flex-row gap-12px">
                            <div className="w-[50%]">Insurance & door-to-door service</div>
                            <ul className="vehicle-features-list">
                                <li id="results_body"> — </li>
                            </ul>
                        </div>
                        <hr />
                    </dl>
                </div>
            </div>
        </div>
    )
}