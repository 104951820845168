import React from "react";
export default () => {
    return (
        <>
            <div className="sds-page-container">

                <section className="sds-page-section sds-page-section--header">
                    <header className="sds-page-section__header">
                        <nav aria-label="breadcrumb">
                            <ul className="sds-breadcrumb sds-breadcrumb--mobile-custom">
                                <li><a data-linkname="home" data-component="breadcrumb" href="https://www.cars.com/">Home</a>
                                </li>
                                <li className="sds-breadcrumb__parent flex">
                                    <span className="sds-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                                            fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" aria-labelledby="title" aria-describedby="description"
                                            role="img">
                                            <title id="title">arrow-left</title>
                                            <desc id="description">An arrow pointing left.</desc>
                                            <path d="M19 12H6M12 5l-7 7 7 7"></path>
                                        </svg>
                                    </span>
                                    <span>
                                        <a data-linkname="home" data-component="breadcrumb"
                                            href="https://www.cars.com/">Home</a>
                                    </span>
                                </li>
                                <li aria-current="page">Car shipping</li>
                            </ul>
                        </nav>


                        <div className="md:text-[52px] text-[32px]">
                            <b>Car shipping</b>
                        </div>
                    </header>
                </section>
            </div>
        </>
    )
}