import React from "react";
import privacyoptions from "../privacyoptions.svg"
export default () => {
    return (
        <>
            <footer id="global-footer" className="global-footer">
                <div className="sds-container">
                    <div className="global-footer-group accordion-group">
                        <div id="footer-header-1" role="heading" className="accordion-header" tabindex="0" aria-level="3">
                            <span className="global-footer-heading accordion-section-title">Buying &amp; Selling</span>
                            <spark-svg name="chevron-down" className="accordion-section-icon"></spark-svg>
                        </div>
                        <div id="footer-section-1" role="region" className="accordion-panel" aria-labelledby="footer-header-1">
                            <ul className="global-footer-list sds-list">

                                <li><a data-linkname="finance" data-component="footer-buying-selling"
                                    data-activity-rule-type="page-over-page" data-component-index="1"
                                    href="https://www.cars.com/finance/">Financing</a></li>

                                <li><a data-linkname="footer-find-a-car" data-component="footer-buying-selling"
                                    data-activity-rule-type="page-over-page" data-component-index="2"
                                    href="https://www.cars.com/shopping/">Find a Car</a></li>

                                <li><a data-linkname="dealer-search-results" data-component="footer-buying-selling"
                                    data-click-intent-id="dealer-search-result-page-from-footer"
                                    data-activity-rule-type="page-over-page" data-component-index="3"
                                    href="https://www.cars.com/dealers/buy/" trc="" trid="v5w1syrg4ibKY6n6GGgg9Q">Find a
                                    Dealer</a></li>

                                <li><a data-linkname="sitemap-city-listings" data-component="footer-buying-selling"
                                    data-activity-rule-type="page-over-page" data-component-index="4"
                                    href="https://www.cars.com/sitemap/city-listings/">Listings by City</a></li>

                                <li><a data-linkname="shop-cpo-index" data-component="footer-buying-selling"
                                    data-activity-rule-type="page-over-page" data-component-index="5"
                                    href="https://www.cars.com/cpo/">Certified Pre-Owned</a></li>

                                <li><a data-linkname="calculate-payment" data-component="footer-buying-selling"
                                    data-activity-rule-type="page-over-page" data-component-index="6"
                                    href="https://www.cars.com/car-loan-calculator/">Car Payment Calculators</a></li>

                                <li><a data-linkname="consumer-reviews" data-component="footer-buying-selling"
                                    data-activity-rule-type="page-over-page" data-component-index="7"
                                    href="https://www.cars.com/reviews/">Car Reviews &amp; Ratings</a></li>

                                <li><a data-linkname="research-compare" data-component="footer-buying-selling"
                                    data-activity-rule-type="page-over-page" data-component-index="8"
                                    href="https://www.cars.com/research/compare/">Compare Side by Side</a></li>

                                <li><a data-linkname="fraud-awareness" data-component="footer-buying-selling"
                                    data-activity-rule-type="page-over-page" data-component-index="9"
                                    href="https://www.cars.com/fraud-awareness/">Fraud Awareness</a></li>

                                <li><a data-linkname="sell" data-component="footer-buying-selling"
                                    data-activity-rule-type="page-over-page" data-component-index="10"
                                    href="https://www.cars.com/sell/" trc="" trid="uGakxDvubMjoKfiGth6kw3">Sell Your Car</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className="global-footer-group global-footer-group-stack">
                        <div className="accordion-group footer-dealer-group">
                            <div id="footer-header-2" role="heading" className="accordion-header" tabindex="0" aria-level="3">
                                <span className="global-footer-heading accordion-section-title">Explore Our Brand</span>
                                <spark-svg name="chevron-down" className="accordion-section-icon"></spark-svg>
                            </div>
                            <div id="footer-section-2" role="region" className="accordion-panel" aria-labelledby="footer-header-2">
                                <ul className="global-footer-list sds-list">

                                    <li><a aria-describedby="new-tab-link" aria-label="NewCars.com, External link"
                                        className="sds-link--ext" data-linkname="footer-newcarscom"
                                        data-component="footer-partners" data-component-index="1"
                                        data-activity-rule-type="custom" href="https://www.newcars.com/" rel="noopener "
                                        target="_blank">NewCars.com<spark-svg name="arrow-up-right"
                                            className="sds-text-field__icon"></spark-svg></a></li>

                                    <li><a aria-describedby="new-tab-link" aria-label="DealerRater, External link"
                                        className="sds-link--ext" data-linkname="footer-dealerrater"
                                        data-component="footer-partners" data-component-index="2"
                                        data-activity-rule-type="custom" href="https://www.dealerrater.com/" rel="noopener "
                                        target="_blank">DealerRater<spark-svg name="arrow-up-right"
                                            className="sds-text-field__icon"></spark-svg></a></li>

                                </ul>
                            </div>
                        </div>
                        <div className="accordion-group">
                            <div id="footer-header-3" role="heading" className="accordion-header" tabindex="0" aria-level="3">
                                <span className="global-footer-heading accordion-section-title">For Dealers</span>
                                <spark-svg name="chevron-down" className="accordion-section-icon"></spark-svg>
                            </div>
                            <div id="footer-section-3" role="region" className="accordion-panel" aria-labelledby="footer-header-3">
                                <ul className="global-footer-list sds-list">

                                    <li><a aria-describedby="new-tab-link" aria-label="Dealer Login, External link"
                                        className="sds-link--ext" data-linkname="footer-dealer-login"
                                        data-activity-rule-type="custom" href="https://dealerdash.cars.com/" rel="noopener "
                                        target="_blank">Dealer Login<spark-svg name="arrow-up-right"
                                            className="sds-text-field__icon"></spark-svg></a></li>

                                    <li><a aria-describedby="new-tab-link" aria-label="GrowWithCars.com, External link"
                                        className="sds-link--ext" data-linkname="footer-grow-with-cars"
                                        data-activity-rule-type="custom" href="https://www.growwithcars.com/"
                                        rel="noopener " target="_blank">GrowWithCars.com<spark-svg name="arrow-up-right"
                                            className="sds-text-field__icon"></spark-svg></a></li>

                                    <li><a aria-describedby="new-tab-link" aria-label="FUEL, External link"
                                        className="sds-link--ext" data-linkname="footer-fuel" data-activity-rule-type="custom"
                                        href="https://fuel.cars/" rel="noopener " target="_blank">FUEL<spark-svg
                                            name="arrow-up-right" className="sds-text-field__icon"></spark-svg></a></li>

                                    <li><a aria-describedby="new-tab-link" aria-label="Dealer Inspire, External link"
                                        className="sds-link--ext" data-linkname="footer-dealer-inspire"
                                        data-activity-rule-type="custom" href="https://www.dealerinspire.com/"
                                        rel="noopener " target="_blank">Dealer Inspire<spark-svg name="arrow-up-right"
                                            className="sds-text-field__icon"></spark-svg></a></li>

                                    <li><a aria-describedby="new-tab-link" aria-label="DealerRater, External link"
                                        className="sds-link--ext" data-linkname="footer-dealerrater/dealers"
                                        data-activity-rule-type="custom" href="https://www.dealerrater.com/dealers"
                                        rel="noopener " target="_blank">DealerRater<spark-svg name="arrow-up-right"
                                            className="sds-text-field__icon"></spark-svg></a></li>

                                    <li><a aria-describedby="new-tab-link" aria-label="Accu-Trade, External link"
                                        className="sds-link--ext" data-linkname="footer-accutrade"
                                        data-activity-rule-type="custom" href="https://accu-trade.com/" rel="noopener "
                                        target="_blank">Accu-Trade<spark-svg name="arrow-up-right"
                                            className="sds-text-field__icon"></spark-svg></a></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="global-footer-group accordion-group">
                        <div id="footer-header-4" role="heading" className="accordion-header" tabindex="0" aria-level="3">
                            <span className="global-footer-heading accordion-section-title">Our Company</span>
                            <spark-svg name="chevron-down" className="accordion-section-icon"></spark-svg>
                        </div>
                        <div id="footer-section-4" role="region" className="accordion-panel" aria-labelledby="footer-header-4">
                            <ul className="global-footer-list sds-list">

                                <li><a data-linkname="footer-about-cars" data-component="footer-our-company"
                                    data-component-index="1" data-activity-rule-type="page-over-page"
                                    href="https://www.cars.com/about/">About Cars.com</a></li>

                                <li><a data-linkname="footer-contact-cars" data-component="footer-our-company"
                                    data-component-index="2" data-activity-rule-type="page-over-page"
                                    href="https://www.cars.com/contact/">Contact Cars.com</a></li>

                                <li><a aria-describedby="new-tab-link" aria-label="Investor Relations, External link"
                                    className="sds-link--ext" data-linkname="footer-investor-relations"
                                    data-component="footer-our-company" data-component-index="3"
                                    data-activity-rule-type="custom" href="https://investor.cars.com/overview/default.aspx"
                                    rel="noopener " target="_blank">Investor Relations<spark-svg name="arrow-up-right"
                                        className="sds-text-field__icon"></spark-svg></a></li>

                                <li><a data-linkname="footer-careers" data-component="footer-our-company"
                                    data-activity-rule-type="page-over-page" data-component-index="4"
                                    href="https://www.cars.com/careers/">Careers</a></li>

                                <li><a aria-describedby="new-tab-link" aria-label="Licensing &amp; Reprints, External link"
                                    className="sds-link--ext" data-linkname="footer-licensing-reprints"
                                    data-component="footer-our-company" data-component-index="5"
                                    data-activity-rule-type="custom"
                                    href="https://info.wrightsmedia.com/cars-licensing-reprints" rel="noopener "
                                    target="_blank">Licensing &amp; Reprints<spark-svg name="arrow-up-right"
                                        className="sds-text-field__icon"></spark-svg></a></li>

                                <li><a data-linkname="footer-site-map" data-component="footer-our-company"
                                    data-component-index="6" data-activity-rule-type="page-over-page"
                                    href="https://www.cars.com/sitemap/">Site Map</a></li>

                                <li><a data-linkname="footer-feedback" data-component="footer-our-company"
                                    data-activity-rule-type="custom" data-component-index="7" data-toggle="modal"
                                    data-target="#qualtrics-modal" href="#" data-modal-registered="1">Feedback</a></li>

                            </ul>
                        </div>
                    </div>
                    <div className="global-footer-group global-footer-apps-and-social">
                        <div className="global-footer-social-header">
                            <span className="global-footer-heading">Our Mobile App</span>
                        </div>
                        <div className="global-footer-mobile-apps">
                            <div className="global-footer-mobile-buttons flex desktop:flex-row flex-col">
                                <a href="https://apps.apple.com/us/app/cars-com-new-used-cars/id353263352"
                                    data-linkname="footer-app-store" data-activity-rule-type="custom">
                                    <img alt="App Store download" className="app-store-button"
                                        src={require("../app-store-badge-us-black-1.webp")}/>
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.cars.android&amp;hl=en_US"
                                    data-linkname="footer-google-play" data-activity-rule-type="custom">
                                    <img alt="Google Play download" className="google-play-button"
                                        src={require("../google-play-badge-us-1.webp")}/>
                                </a>
                            </div>
                        </div>
                        <div className="global-footer-social-header">
                            <span className="global-footer-heading">Connect With Us</span>
                        </div>
                        <div className="global-footer-social-buttons">
                            <ul className="social-footer-list">
                                <li className="social-link">
                                    <a label="TikTok, External link"
                                        className="sds-link--ext sds-button--icon sds-icon--contain tiktok"
                                        href="https://www.tiktok.com/@carsdotcom" target="_blank">
                                        <img src={require('../social_tiktok.png')}/>
                                    </a>
                                </li>
                                <li className="social-link">
                                    <a label="Facebook, External link"
                                        className="sds-link--ext sds-button--icon sds-icon--contain facebook"
                                        href="https://www.facebook.com/CarsDotCom/" target="_blank">
                                        <img src={require("../social_facebook.png")}/>
                                    </a>
                                </li>
                                <li className="social-link">
                                    <a label="Youtube, External link"
                                        className="sds-link--ext sds-button--icon sds-icon--contain youtube"
                                        href="https://www.youtube.com/user/Carscom/" target="_blank">
                                        <img src={require("../social_youtube.png")}/>
                                    </a>
                                </li>
                                <li className="social-link">
                                    <a label="Instagram, External link"
                                        className="sds-link--ext sds-button--icon sds-icon--contain instagram"
                                        href="https://www.instagram.com/carsdotcom/" target="_blank">
                                        <img src={require("../social_instagram.png")}/>
                                    </a>
                                </li>

                                <li className="social-link">
                                    <a label="Pinterest, External link"
                                        className="sds-link--ext sds-button--icon sds-icon--contain pinterest"
                                        href="https://www.pinterest.com/carsdotcom/" target="_blank">
                                        <img src={require("../social_pininterest.png")}/>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div className="global-copyright">
                    <div className="sds-container">
                        <ul className="sds-list">

                            <li><a data-linkname="footer-terms-of-service" data-component="footer-legal"
                                data-component-index="1" data-activity-rule-type="page-over-page"
                                href="https://www.cars.com/about/terms/">Terms &amp; Conditions of Use</a></li>

                            <li><a data-linkname="footer-privacy-statement" data-component="footer-legal"
                                data-component-index="2" data-activity-rule-type="page-over-page"
                                href="https://www.cars.com/about/privacy/">Privacy Notice</a></li>

                            <li><a data-linkname="footer-personal-information" data-component="footer-legal"
                                data-activity-rule-type="page-over-page" data-component-index="3"
                                href="https://www.cars.com/about/ccpa-privacy-notice/">California Privacy Notice</a></li>

                            <li><a data-linkname="footer-personal-information" data-component="footer-legal"
                                data-activity-rule-type="page-over-page" data-component-index="4" className="flex"
                                href="https://www.cars.com/about/ccpa-privacy-notice/#exercising-access-use-limitation-data-portability-deletion-and-correction-rights">My
                                Privacy Choices<img alt="California Consumer Privacy Act (CCPA) Opt-Out Icon" height="14px"
                                    src={privacyoptions} className="w-8"/></a></li>

                            <li><a className="optanon-show-settings" data-component="footer-legal" data-component-index="5" href="#"
                                trc="" trid="f8a95eb0-4f9a-467a-80b5-d7c890383917">Cookie Preferences</a></li>

                            <li><a data-linkname="footer-accessibility" data-component="footer-legal"
                                data-activity-rule-type="page-over-page" data-component-index="6"
                                href="https://www.cars.com/about/accessibility/">Accessibility Statement</a></li>

                            <li><a data-linkname="footer-ad-choices" data-component="footer-legal"
                                data-activity-rule-type="page-over-page" data-component-index="7"
                                href="https://www.cars.com/about/ad-choices/">Ad Choices</a></li>

                        </ul>
                        <div className="global-copyright-copy">© 2024 Cars.com. All rights reserved.</div>
                    </div>
                </div>
                <div className="sds-modal sds-modal-one" aria-modal="true" id="qualtrics-modal">
                    <div className="sds-modal__content">
                        <div className="sds-modal__content-head sds-modal__content-head">
                            <div className="sds-icon--target btn-close js-close" role="button">
                                <span className="sds-icon">
                                    <spark-svg name="close"></spark-svg>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </footer>
        </>
    )
}