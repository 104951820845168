import React from "react";
export default () => {
    return (

        <section className="">
            <div className="text-[36px] "><b>Calculate your car's shipping cost</b></div>
            <div className="text-[16px] text-wrap overflow-clip">Cars.com has partnered with <a href="https://ussautobrokers.com/"
                target="_blank">ussautobrokers.com</a>, a trusted vehicle shipping provider. USS AUTOMOTIVE LLC helps you ship your vehicle quickly and securely with its nationwide network of verified
                transporters. Quote and ship anywhere in the contiguous United States. <b>Please note</b>,
                your car must be able to drive onto the transport.</div>
            <p className="title-disclaimer">All fields are required.</p>
            <div style={{ color: 'lightcoral', fontweight: 'bold' }} id="errors"></div>
            <p>&nbsp;</p>
        </section>
    )
}