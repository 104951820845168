import React, { useState } from "react";
import Left from "./Left.js";
import Right from "./Right.js";
import Top from "./Top.js";
import Modal from "./Modal.js";
export default () => {
    
    const [modal , setModal]=useState(false);
    return (
        <div 
         className="h-full">
            <Modal modal={modal} setModal={setModal} />

            <section role="main" className="w-[100%] bg-gray-50 flex pt-[64px] justify-center ">

                <section className="max-w-[1170px]  px-[20px] w-full flex flex-col justify-center ">
                    <div className="flex justify-center flex-col items-center  ">
                        <div className="tablet:max-w-full max-w-[478px] flex flex-col items-center tablet:justify-center">

                            <Top />
                        </div>
                        <section className="w-full flex items-center  text-[14px] tablet:items-start tablet:flex-row tablet:justify-start flex-col tablet:gap-[50px]" >
                            <div className="max-w-[478px] ">

                                <Left  modal={modal} setModal={setModal} />
                            </div>
                            <div className="w-full max-w-[478px] teblet:ml-[20px] tablet:w-[478px]">

                                <Right />
                            </div>

                        </section>

                    </div>
                </section>

            </section >
        </div>
    )

}