import React, { useState } from "react";
export default () => {
    const [display, setDisplay] = useState(false);
    const [display1, setDisplay1] = useState(false);
    const [display2, setDisplay2] = useState(false);

    const [display3, setDisplay3] = useState(false);
    const [display4, setDisplay4] = useState(false);

    const [display5, setDisplay5] = useState(false);
    const [display6, setDisplay6] = useState(false);

    return (
        <>
            <div className="flex flex-row justify-center ">
                <div id="faq-container" className="flex flex-col w-[1170px] text-[16px] pt-[48px] px-[16px]">
                    <div className=" text-[36px]">Car shipping FAQ</div>
                    <div className="page-section-content faq-accordion-section">
                        <a onClick={() => setDisplay(!display)}>What is car shipping and how does it work?</a>
                        <p style={{ display: `${display ? "block" : "none"}` }}>Car shipping is a service that transports cars from one
                            location to another. It's a convenient solution if you're relocating or
                            you've purchased a car in another state and are not able to drive the
                            vehicle to its destination.
                        </p>
                        <p>&nbsp;</p>
                        <a onClick={() => setDisplay1(!display1)}>How much does car shipping cost?</a>
                        <p id="help_cost" style={{ display: `${display1 ? 'block' : 'none'}` }} >Car-shipping rates are usually calculated by the mile and
                            vary based on the total mileage the vehicle must travel. The industry
                            average to ship a car short distances (0-500 miles) is around $2 per
                            mile. For medium distances (500-1,500 miles), this figure drops to
                            around $1 per mile. And, for long distances (over 1,500 miles), shipping
                            costs around 60 cents per mile.
                        </p>
                        <p>&nbsp;</p>
                        <a onClick={() => setDisplay2(!display2)}>What factors affect car-shipping costs?</a>
                        <div id="help_factors" style={{ display: `${display2 ? 'block' : 'none'}` }}>
                            <p>A number of factors determine car shipping costs
                                including the distance of travel, delivery location, whether the car is
                                shipped in an open or enclosed carrier, the vehicle type and the
                                shipping date. Find more information on each below:</p>
                            <p>&nbsp;</p>
                            <h5>Distance, location and route</h5>
                            <p>Total distance in miles is the primary factor in
                                determining the cost to ship a car. Similarly, shipping a car to
                                locations that require drivers to navigate through heavier traffic
                                congestion could result in higher shipping costs.</p>
                            <p>&nbsp;</p>
                            <h5>Shipment type</h5>
                            <p>Choosing to ship the vehicle in an enclosed carrier can cost 30-40% more than open transport.</p>
                            <p>&nbsp;</p>
                            <h5>Vehicle details</h5>
                            <p>Your car's value, dimensions and condition can all impact
                                shipping costs. Larger heavier cars may cost more to ship.
                                Additionally, you may want to opt for the enclosed transport option if
                                you're shipping a high-value car.</p>
                            <p>&nbsp;</p>
                            <h5>Shipping date</h5>
                            <p>It's best to schedule a shipping date as far in advance as possible – you may have to pay more
                                money for expedited transport.</p>
                            <p>&nbsp;</p>
                            <h5>Time of year</h5>
                            <p>Seasonality can impact shipping costs. For example, you
                                may need to pay more in the winter if you're shipping your car to a
                                warmer part of the country.</p>
                        </div>
                        <p>&nbsp;</p>
                        <a onClick={() => setDisplay3(!display3)}>How long does it take to ship a car?</a>
                        <p id="help_long" style={{ display: `${display3 ? "block" : "none"}` }}>Although shipping times can be influenced by all the
                            factors listed above, most deliveries can be made within 7-10 days. This
                            timeline may be quicker for shorter shipping distances.
                        </p>

                        <p>&nbsp;</p>
                        <a onClick={() => setDisplay4(!display4)}>Do I need car shipping insurance and what does it include?</a>
                        <div id="help_insurance" style={{ display: `${display4 ? "block" : "none"}` }}>
                            <p>Vehicle transport insurance coverage requirements can
                                vary by state, so be sure to ask for your shipping provider's insurance
                                policy to see what it includes. If the full shipment process (pickup,
                                transport and delivery) is not covered, or your car's value exceeds the
                                coverage limit, you should look into securing additional cargo
                                insurance.</p>
                        </div>

                        <p>&nbsp;</p>
                        <a onClick={() => setDisplay5(!display5)}>Who is USS AUTOMOTIVE LLC ?</a>
                        <div id="help_company" style={{ display: `${display5 ? "block" : "none"}` }}>
                            <p>Cars.com has partnered with <a href="https://ussautobrokers.com/"
                                target="_blank">ussautobrokers.com</a>, a trusted vehicle shipping provider. USS AUTOMOTIVE LLC helps you ship your vehicle quickly and securely with its nationwide network of
                                verified transporters.</p>
                            <p>Cars.com provides external links as a convenience to
                                customers and for informational purposes only. Cars.com does not endorse
                                or approve any of the content (including products, services or
                                opinions) present on external websites and bears no responsibility for
                                the accuracy or legality of the content of the external site or
                                subsequent links from an external site.</p>
                            <p>Cars.com and its affiliates ("CARS") are not in any way
                                associated with the owner or operator of USS AUTOMOTIVE LLC. CARS does not endorse
                                or approve and makes no warranties, representations or undertaking
                                relating to the content of USS AUTOMOTIVE LLC.</p>
                        </div>
                        <p>&nbsp;</p>
                        <a onClick={() => setDisplay6(!display6)}>What do I do next with my quote?</a>
                        <div id="help_next" style={{ display: `${display6 ? "block" : "none"}` }}>
                            <p>If you'd like to move forward with your quote, you can
                                connect directly with USS AUTOMOTIVE LLC. Simply place your order and USS AUTOMOTIVE LLC
                                will find a shipping provider to fulfill it — no paperwork required.
                                After your vehicle is picked up on the agreed-upon date, you will be
                                able to track it as it's transported to its destination and receive
                                status updates from the driver.</p>
                            <p>To ship with USS AUTOMOTIVE LLC, the vehicle must be operational,
                                it cannot be oversized or undersized, and it must be shipped within the
                                lower 48 states. After your car is delivered, you'll be asked to sign a
                                document that confirms the vehicle arrived damage-free, or you can
                                designate another party to sign for it. Payment is processed only when
                                your vehicle is delivered. All payments are processed by USS AUTOMOTIVE LLC. CARS
                                does not process any payments or perform financial verification for
                                payment purposes.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}