import React from "react";
import topcar from '../topcar.svg';
import undercar from "../undercar.svg";
export default (props) => {
    const { modal, setModal } = props;
    console.log(modal, "o--------------");
    return (
        <div onClick={()=>setModal(false)} className={`absolute overflow-auto  top-0 left-0 w-full h-full justify-center flex z-30 bg-gray-100 ${modal ? "block" : "hidden"}`}
            style={{ display: "block!important", backgroundColor: "rgba(0, 0, 0, 0.4)" }} >
            <div id="modal-transport" className="modal top-32 fixed bg-white w-[30%] min-w-[350px] h-auto overscroll-auto  p-[20px] flex justify-center" >
                <div className="modal-content">
                    <div className="flex flex-row justify-between ">
                        <div className="text-[36px]" id="title">Open vs. enclosed transport</div>
                        <span className="close text-[28px]"
                            onClick={() => setModal(true)} style={{cursor: "pointer"}}>&times;</span>


                    </div>
                    <div className="transport-modal-content-block">
                        <p>
                            <strong>Open transport</strong>
                        </p>
                        <img src={topcar} alt='' />
                        <p>
                            This option is the least expensive because more cars fit on each trailer. More
                            common,
                            easier to book, open trailers give you more flexibility when it comes to pick-up
                            and
                            delivery dates. Your car will be exposed to weather conditions while being
                            transported, but
                            this is a great option if cost is a decision factor.
                        </p>
                    </div>
                    <div className="transport-modal-content-block">
                        <p>
                            <strong>Enclosed transport</strong>
                        </p>
                        <img src={undercar} alt="" />
                        <p>
                            This is the most expensive option. Enclosed car trailers can move fewer cars
                            simultaneously, and are heavier which raises the fuel costs. This is a great
                            option if you
                            want to shield your car from weather conditions or if you are transporting an
                            expensive or
                            collectible car.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}