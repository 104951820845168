import React from "react";
export default () => {

    return (
        <>
            <div >

                <header className="global-header ">
                    <section className="container">
                        <a className="header-logo w-20" href="https://www.cars.com/">
                            <img alt="Cars.com" src={require("../Task/cars_logo_primaryx2.png")} />
                        </a>
                        <nav className="pl-[48px] ">
                            <ul className="header-links">
                                <li className="header-link">
                                    <a href="https://www.cars.com/shopping/">Cars for Sale</a>
                                </li>
                                <li className="header-link">
                                    <a href="https://www.cars.com/new-cars/">New Cars</a>
                                    <span className="new-banner">NEW</span>
                                </li>
                                <li className="header-link">
                                    <a href="https://www.cars.com/research/">Research &amp; Reviews</a>
                                </li>
                                <li className="header-link">
                                    <a href="https://www.cars.com/news/">News &amp; Videos</a>
                                </li>
                                <li className="header-link">
                                    <a href="https://www.cars.com/sell/">Sell Your Car</a>
                                </li>
                                <li className="header-link">
                                    <a href="https://www.cars.com/finance/">Financing</a>
                                </li>
                            </ul>
                        </nav>
                    </section>
                </header>
            </div>
            <hr/>

        </>
    )
}