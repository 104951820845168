import React from "react";
// import Generator from '../problem/Generator';
// import UI from '../problem/UI';
// import Problem6 from "../problem/6.js";
// import Component1 from "../problem/Usecon.js"
// import '../calculators.css'
import Task from "../Task/Header.js";
import "../css.css";
import '../output.css';
import '../common1.css';
import Header from "../Task/Header.js";
import Body from "../Task/Body/Body.js";
import Home from "../Task/Home.js";
import Medium from "../Task/Medium/Medium.js";
import Footer from "../Task/Footer/Footer.js";
const App = (props) => {
    // const {modal , setModal}=props;
    return (
        <div className="relative overflow-auto"
        >
            {/* <UI/> */}
            {/* <Generator/>
        <Problem6 /> */}
            {/* <Component1 /> */}
            <Header />
            <Home />
            <Body />
            <Medium />
            <Footer />
        </div>
    )
}
export default App;
